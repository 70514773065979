"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '@/store'
import {Message} from 'element-ui'
import i18n from '@/i18n'

// if (store.state.accessToken) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
//     axios.defaults.headers.common['Authorization'] =  store.state.accessToken
// axios.defaults.headers.common['Roles'] =  store.state.roles
// }
// 设置 post、put 默认 Content-Type
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'

let config = {
  // baseURL: process.env.VUE_APP_DEV_URL
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    store.state.loading = true
    // const reg = new RegExp('^/api')
    // // config.url = config.url.replace(reg, '')
    // if (config.url.search(reg) > -1) {
    //   config.url = 'http://192.168.0.80:9003' + config.url.replace(reg, '')
    // } else {
    //   config.url = config.url.replace(reg, '')
    // }
    if (config.url !== "/auth/login" && config.url !== "/channel/search_msg_total"
      && config.url !== "/channel/search_channel_message" && config.url !== "/channel/search_mychannel" && config.url !== "/channel/update_sys_message" && config.url !== "/image/download_file2") {
      if (store.state.orgcodeList) {
        config.data.orgcode_list = store.state.orgcodeList
      }
    }
    if (config.data.orgcodelistFlag === false) {
      delete config.data.orgcodelistFlag
      delete config.data.orgcode_list
    }
    if (config.method === 'post' || config.method === 'put') {
      // post、put 提交时，将对象转换为string, 为处理后台解析问题
      config.data = JSON.stringify(config.data)
    }
    if (store.state.accessToken) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      // config.headers.Authorization = localStorage.getItem('accessToken')
      axios.defaults.headers.common['Authorization'] = store.state.accessToken
    }
    // if (store.state.roles) {
    //     axios.defaults.headers.common['Roles'] = store.state.Roles
    // }
    // Do something before request is sent
    return config;
  },
  function (error) {
    store.state.loading = false
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    store.state.loading = false
    let {data} = response
    // Do something with response data
    return data
  },
  function (error) {
    store.state.loading = false
    let err = JSON.parse(JSON.stringify(error))
    if (err.status) {
      switch (error.response.status) {
        case 400:
          Message.error(i18n.t('HttpMessage.400message'))
          break
        case 401:
          Message.error(i18n.t('HttpMessage.401message'))
          break
        case 403:
          Message.error(i18n.t('HttpMessage.403message'))
          break
        case 404:
          Message.error(i18n.t('HttpMessage.404message'))
          break
        case 408:
          Message.error(i18n.t('HttpMessage.408message'))
          break
        case 500:
          Message.error(i18n.t('HttpMessage.500message'))
          break
        case 501:
          Message.error(i18n.t('HttpMessage.501message'))
          break
        case 502:
          Message.error(i18n.t('HttpMessage.503message'))
          break
        case 503:
          Message.error(i18n.t('HttpMessage.503message'))
          break
        case 504:
          Message.error(i18n.t('HttpMessage.504message'))
          break
        case 505:
          Message.error(i18n.t('HttpMessage.505message'))
          break
      }
    } else {

      Message.error(i18n.t('HttpMessage.othermessage'))
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

// Plugin.install = function (Vue, options) {
Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
