/**
 * Author:Susan
 * Create Time:2019-06-12
 * FileName:   transDate.js
 * Function:  时间戳处理
 **/
Date.prototype.format = function(format) {

	var date = {
		"M+": this.getMonth() + 1,
		"d+": this.getDate(),
		"h+": this.getHours(),
		"m+": this.getMinutes(),
		"s+": this.getSeconds(),
		"q+": Math.floor((this.getMonth() + 3) / 3),
		"S+": this.getMilliseconds()
	};
	if (/(y+)/i.test(format)) {
		format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	for (var k in date) {
		if (new RegExp("(" + k + ")").test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length == 1 ?
				date[k] : ("00" + date[k]).substr(("" + date[k]).length));
		}
	}
	return format;
}

// 时间转换
export function add0(m) {
	return m < 10 ? '0' + m : m
}

// 时差 --要比现在晚4个小时
export function getDateDiff(dateTimeStamp) {
	var minute = 1000 * 60
	var hour = minute * 60
	// var day = hour * 24
	// var month = day * 30
	var now = new Date().getTime()
	var diffValue = dateTimeStamp - now
	if (diffValue < 0) { //开始时间-现在时间为负---则说明它早于现在
		return [false, 'late']
	}
	var hourC = diffValue / hour
	if (hourC < 4) {
		return [false, 'nolate'] //开始时间-现在时间<4小时---则说明不行
	} else {
		return [true]
	}
}

// 时差 --开始时间比，结束时间早几个小时，早吗
export function getDateTwoDiff(sartTimeStamp, dateTimeStamp) {
	var minute = 1000 * 60
	var hour = minute * 60
	// var day = hour * 24
	// var month = day * 30
	// var now = new Date().getTime()
	var diffValue = dateTimeStamp - sartTimeStamp
	if (diffValue <= 0) { //结束时间-开始时间为负---则说明它早于开始时间
		return [false, 'late']
	} else {
		var hourC = diffValue / hour
		return [true, hourC] //结束时间-开始时间为正---则说明可以，返回具体几个小时
	}
}
// 获取当天开始时间戳
export function getTodayStart() {
	let myDate = new Date();
	// 开始月份下标为0
	let nowMonth = parseInt(myDate.getMonth()) + parseInt(1);
	return Date.parse(myDate.getFullYear() + '/' + nowMonth + '/' + myDate.getDate());
}
// 获取当天当时的13位时间戳
export function getTodayNow() {
	var myDate = new Date();
	// 开始月份下标为0
	return Date.parse(myDate);
}
//获取当前年月日
export function getTodayYmd() {
	var myDate = new Date(); //获取系统当前时间
	var year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
	var month = 1 + myDate.getMonth(); //获取当前月份(0-11,0代表1月)
	if (month < 10)
		month = '0' + month
	var day = myDate.getDate()
	if (day < 10)
		day = '0' + day
	var date = String(year) + '/' + String(month) + '/' + String(day)
	return date
}
export function getTodayYm() {
	var myDate = new Date(); //获取系统当前时间
	var year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
	var month = 1 + myDate.getMonth(); //获取当前月份(0-11,0代表1月)
	if (month < 10)
		month = "0" + month;
	var date = String(year) + "/" + String(month);
	return date;
}
//获取当前月日  shiqiu 02-14
export function getTodayMd() {
	var myDate = new Date(); //获取系统当前时间
	var month = 1 + myDate.getMonth(); //获取当前月份(0-11,0代表1月)
	if (month < 10)
		month = '0' + month
	var day = myDate.getDate()
	if (day < 10)
		day = '0' + day
	var date = String(month) + '/' + String(day)
	return date
}
// 获取时分
export function getLocalTime(nS) {
	var newDate = new Date();
	newDate.setTime(nS * 1000);
	return newDate.format('hh:mm');
}

//聚会吧首页时间
export function getTime(nS, enD) {
	var newDate = new Date();
	newDate.setTime(nS);
	var endDate = new Date();
	endDate.setTime(enD);
	let starttime = newDate.format('hh:mm')
	let endtime = endDate.format('hh:mm')
	let startdate = ''
	// console.log('活动日时间戳',nS)
	// console.log('今天时间戳',this.getTodayStart())
	if (nS > getTodayStart()) {
		// console.log('是今天的时间戳')
		startdate = '今天 '
	} else if (parseInt(nS) + parseInt(86400000) > getTodayStart()) { //这里因为我13位时间戳，86400后面多加3个0
		// console.log('是昨天的时间戳')
		startdate = '昨天 '
	} else {
		startdate = newDate.format('MM月dd日 ')
	}
	let result = startdate + starttime + '/' + endtime
	return result
}
// 格式化时间
export function format(timestampToday, spacer) {
	// 判断当天日期 规则2：当天信息不显示日期。如今天的信息显示时间：  14：25
	if (timestampToday > this.getTodayStart())
		return this.getLocalTime(timestampToday);
	// 判断昨天日期 规则3：昨天的信息需要标记。如：昨天 14：25
	else if (parseInt(timestampToday) + parseInt(86400) > this.getTodayStart())
		return '昨天 ' + this.getLocalTime(timestampToday);
	// 判断昨天以前 规则4：昨天以前的信息需要显示日期，如：2017年4月18日 14：25
	else
		return this.getCompleteTime(timestampToday, spacer);
}
//时间转换Thu May 12 2017 08:00:00 GMT+0800转化为2019/04/12 09：00
export function getTransformTime(datatime) {
	// var date = new Date('Thu May 12 2017 08:00:00 GMT+0800 (中国标准时间)');
	var date = new Date(datatime);
	// let date_value=date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
	let date_value = add0(date.getFullYear()) + '/' + add0(date.getMonth() + 1) + '/' + add0(date.getDate()) + ' ' + add0(
		date.getHours()) + ':' + add0(date.getMinutes());
	return date_value
}
//聚会吧2个时间转换并返回一个time，我参加里面时间转换方法
export function getDateTime(start_time, end_time) {
	var date = new Date(start_time);
	let startime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var date1 = new Date(end_time);
	let endtime = date1.getFullYear() + '/' + (date1.getMonth() + 1) + '/' + date1.getDate() + ' ' + date1.getHours() +
		':' + date1.getMinutes() + ':' + date1.getSeconds();
	var timestamp1 = Date.parse(new Date(startime));
	var timestamp2 = Date.parse(new Date(endtime));
	//2014-07-10 10:21:12的时间戳为：1404958872
	return getTime(timestamp1, timestamp2)
}
//13位时间戳转换为 2019-06-12 10:28格式
// export function getDateTimeone(time) {

//         var date = new Date(Number(time));
//         let sTime=date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
//         var timestamp1 = Date.parse(new Date(sTime));
//         var newDate = new Date();
//         newDate.setTime(timestamp1);     //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
//         // return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
//         return newDate.format('yyyy-MM-dd hh:mm');//2019-06-12 10:28格式
//     }
// ios
//13位时间戳转换为 2019-06-12 10:28格式
export function getDateTimeone(time) {
	var date = new Date(Number(time));
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
	return newDate.format('yyyy/MM/dd hh:mm'); //2019-06-12 10:28格式
}

//13位时间戳转换为 06-12 10:28格式
export function getDateTimeone2(time) {
	var date = new Date(Number(time));
	let sTime = (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//06-12 10:28:06格式
	return newDate.format('MM/dd hh:mm'); //06-12 10:28格式
}

//13位时间戳转换为 06-12 10:28格式
export function getDateTimeone3(time) {
	var date = new Date(Number(time));
	let sTime = (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//06-12 10:28:06格式
	return 	newDate.format('hh:mm'); //06-12 10:28格式
}

//13位时间戳转换为 2019-06-12 格式只保留年月日+一个空格
export function getDateone(time) {
	var date = new Date(time);
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
	return newDate.format('yyyy/MM/dd '); //2019-06-12 10:28格式
}
//13位时间戳转换为 2019-06 格式只保留年月
export function getYearMonth(time) {
	var date = new Date(time);
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
	return newDate.format('yyyy-MM'); //2019-06格式
}
// 获取时间戳 2019-06-14 17:50:06--13位时间戳
export function getTimestamp(time) {
	var date = new Date(time);
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	return timestamp1
}
//时间转换Thu May 12 2017 08:00:00 GMT+0800转化为13位时间戳---再转换为2019-04-12 09：00
export function getTimeOther(time) {
	var date1 = new Date(time);
	let sTime1 = date1.getFullYear() + '/' + (date1.getMonth() + 1) + '/' + date1.getDate() + ' ' + date1.getHours() + ':' +
		date1.getMinutes() + ':' + date1.getSeconds();
	var timestamp2 = Date.parse(new Date(sTime1));
	var newDate = new Date();
	newDate.setTime(timestamp2); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	return newDate.format('yyyy/MM/dd hh:mm:ss'); //2019/06/12 10:28:06格式
}

//时间转换Thu May 12 2017 08:00:00 GMT+0800转化为13位时间戳---再转换为2019-04-12
export function getTimeHours(time) {
	var date1 = new Date(time);
	let sTime1 = date1.getFullYear() + '/' + (date1.getMonth() + 1);
	var timestamp2 = Date.parse(new Date(sTime1));
	var newDate = new Date();
	newDate.setTime(timestamp2); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	return newDate.format('yyyy/MM'); //2019-06-12 10:28:06格式
}

// export default getFormatTime


//10位时间戳转换为 2019-06-12 10:28格式
export function getDateTimeone10(time) {
	var date = new Date(time);
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1 * 1000); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
	return newDate.format('yyyy/MM/dd hh:mm'); //2019-06-12 10:28格式
}
//10位时间戳转换为 2019/06/12格式
export function getDateTime10(time) {
	var date = new Date(time);
	let sTime = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours() + ':' +
		date.getMinutes() + ':' + date.getSeconds();
	var timestamp1 = Date.parse(new Date(sTime));
	var newDate = new Date();
	newDate.setTime(timestamp1 * 1000); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	// return newDate.format('yyyy-MM-dd hh:mm:ss');//2019-06-12 10:28:06格式
	return newDate.format('yyyy/MM/dd'); //2019/06/12格式
}
//时间转换Thu May 12 2017 08:00:00 GMT+0800转化为13位时间戳---再转换为04-12 09：00
export function getCarTime(time) {
	var date1 = new Date(time);
	let sTime1 = date1.getFullYear() + '/' + (date1.getMonth() + 1) + '/' + date1.getDate() + ' ' + date1.getHours() + ':' +
		date1.getMinutes() + ':' + date1.getSeconds();
	var timestamp2 = Date.parse(new Date(sTime1));
	var newDate = new Date();
	newDate.setTime(timestamp2); //时间戳如果是10位，则这里多乘以1000，如果是13位，则删除*1000
	return newDate.format('MM/dd hh:mm'); //2019-06-12 10:28:06格式
}

//功能：计算两个时间戳之间相差的日时分秒
//begin_time  开始时间戳
//end_time 结束时间戳
export function timediff(stime, etime) {
	let usedTime = etime - stime; //两个时间戳相差的毫秒数
	let days = Math.floor(usedTime / (24 * 3600 * 1000));
	//计算出小时数
	let leave1 = usedTime % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
	let hours = Math.floor(leave1 / (3600 * 1000));
	//计算相差分钟数
	let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
	let minutes = Math.floor(leave2 / (60 * 1000));
	//计算秒数
	let leave3 = leave2 % (60 * 1000);
	let secs = Math.floor(leave3 / 1000);
	// let time = days + "天"+hours+"时"+minutes+"分" + secs + "秒";
	// var time = days;
	return [days, hours, minutes, secs];
}

//获取月份第一天和最后一天时间戳
export function getMonthFirstLast(date) {
	let year = parseInt(date.getFullYear())
	//date 时间戳
	let month = parseInt(date.getMonth() + 1);
	let start_time = Date.parse(new Date(year + '/' + month + '/' + '01'))
	let end_time
	if (month + 1 > 12) {
		end_time = Date.parse(new Date((year + 1) + '/' + '01' + '/' + '01'))
	} else {
		end_time = Date.parse(new Date(year + '/' + (month + 1) + '/' + '01'))
	}
	return {
		start_time,
		end_time
	};
}


//聚会吧首页时间
export function getPartyTime(nS, enD) {
	nS = (parseInt(nS) - 60 * 60 * 1000 * 8).toString();
	enD = (parseInt(enD) - 60 * 60 * 1000 * 8).toString();
	let newDate = new Date();
	newDate.setTime(nS);
	let endDate = new Date();
	endDate.setTime(enD);
	let starttime = newDate.format("hh:mm");
	let endtime = endDate.format("hh:mm");
	let startdate = "";
	if ((getTodayStart() + 60 * 60 * 24 * 1000) > nS > getTodayStart()) {
		// console.log('是今天的时间戳')
		startdate = "今天 ";
	} else {
		startdate = newDate.format("MM月dd日 ");
	}
	let result = startdate + " " + starttime + "至" + startdate + " " + endtime;
	return result;
}

//聚会吧首页时间
export function getPartyTime1(nS, enD) {
	nS = (parseInt(nS) - 60 * 60 * 1000 * 8).toString();
	enD = (parseInt(enD) - 60 * 60 * 1000 * 8).toString();
	let newDate = new Date();
	newDate.setTime(nS);
	let endDate = new Date();
	endDate.setTime(enD);
	let starttime = newDate.format("hh:mm");
	let endtime = endDate.format("hh:mm");
	let startdate = "";
	if ((getTodayStart() + 60 * 60 * 24 * 1000) > nS > getTodayStart()) {
		// console.log('是今天的时间戳')
		startdate = "今天 ";
	} else {
		startdate = newDate.format("MM月dd日 ");
	}
	let result = startdate + starttime + "至" + endtime;
	return result;
}

//聚会吧首页时间
export function getPartyTime2(nS, enD) {
	nS = (parseInt(nS) - 0).toString();
	enD = (parseInt(enD) - 0).toString();
	let newDate = new Date();
	newDate.setTime(nS);
	let endDate = new Date();
	endDate.setTime(enD);
	let starttime = newDate.format("hh:mm");
	let endtime = endDate.format("hh:mm");
	let startdate = "";
	if ((getTodayStart() + 60 * 60 * 24 * 1000) > nS > getTodayStart()) {
		// console.log('是今天的时间戳')
		startdate = "今天 ";
	} else {
		startdate = newDate.format("MM月dd日 ");
	}
	let result = startdate + " " + starttime + "至" + startdate + " " + endtime;
	return result;
}

export function getDates(time) {


	var date = new Date(time);
	//获取当前年份
	var year = date.getFullYear();

	// 获取当前月份

	var nowMonth = date.getMonth() + 1;

	// 获取当前是几号
	var strDate = date.getDate();

	// 对月份进行处理，1-9月在前面添加一个“0”
	if (nowMonth >= 1 && nowMonth <= 9) {
		nowMonth = "0" + nowMonth;
	}

	// 对月份进行处理，1-9号在前面添加一个“0”
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	}

	// 最后拼接字符串，得到一个格式为(YYYY年MM月dd日)的日期
	var newDate = year +"年"+nowMonth + "月" + strDate + "日";
	return newDate
}
export function getDay(time) {

	var date = new Date(time);

	// 获取当前月份

	var nowMonth = date.getMonth() + 1;

	// 获取当前是几号
	var strDate = date.getDate();

	// 对月份进行处理，1-9月在前面添加一个“0”
	if (nowMonth >= 1 && nowMonth <= 9) {
		nowMonth = "0" + nowMonth;
	}

	// 对月份进行处理，1-9号在前面添加一个“0”
	if (strDate >= 0 && strDate <= 9) {
		strDate = "0" + strDate;
	}

	// 最后拼接字符串，得到一个格式为(MM月dd日)的日期
	var newDate = nowMonth + "月" + strDate + "日";
	return newDate
}


export function getTimes(time) {
	// 获取当前小时
	var date = new Date(time);
	var nowHours = date.getHours();

	// 获取当前是几分
	var nowMinutes = date.getMinutes();

	// 对小时进行处理，1-9小时在前面添加一个“0”
	if (nowHours >= 1 && nowHours <= 9) {
		nowHours = "0" + nowHours;
	}

	// 对分进行处理，1-9分钟在前面添加一个“0”
	if (nowMinutes >= 0 && nowMinutes <= 9) {
		nowMinutes = "0" + nowMinutes;
	}

	// 最后拼接字符串，得到一个格式为(HH:mm)的时分
	var newTime = nowHours + ":" + nowMinutes;
	return newTime
}

//照管长辈发布时间
export function dateDiff(timestamp) {
	// 补全为13位
	let arrTimestamp = (timestamp + '').split('');
	for (let start = 0; start < 13; start++) {
		if (!arrTimestamp[start]) {
			arrTimestamp[start] = '0';
		}
	}
	timestamp = arrTimestamp.join('') * 1;

	let minute = 1000 * 60;
	let hour = minute * 60;
	let day = hour * 24;
	// let halfamonth = day * 15;
	let month = day * 30;
	let now = new Date().getTime();
	let diffValue = now - timestamp;

	// 如果本地时间反而小于变量时间
	if (diffValue < 0) {
		return '不久前';
	}

	// 计算差异时间的量级
	let monthC = diffValue / month;
	let weekC = diffValue / (7 * day);
	let dayC = diffValue / day;
	let hourC = diffValue / hour;
	let minC = diffValue / minute;

	// 数值补0方法
	let zero = function(value) {
		if (value < 10) {
			return '0' + value;
		}
		return value;
	}

	// 使用
	if (monthC > 12) {
		// 超过1年，直接显示年月日
		return (function() {
			let date = new Date(timestamp);
			return date.getFullYear() + '年' + zero(date.getMonth() + 1) + '月' + zero(date.getDate()) + '日';
		})();
	} else if (monthC >= 1) {
		return parseInt(monthC) + "月前";
	} else if (weekC >= 1) {
		return parseInt(weekC) + "周前";
	} else if (dayC >= 1) {
		return parseInt(dayC) + "天前";
	} else if (hourC >= 1) {
		return parseInt(hourC) + "小时前";
	} else if (minC >= 1) {
		return parseInt(minC) + "分钟前";
	}
	return '刚刚';
}

export function formatDateTime(inputTime) {
	var date = new Date(inputTime);
	if (date == null || date == undefined) {
		return;
	}
	var y = date.getFullYear();
	var m = date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate();
	d = d < 10 ? ('0' + d) : d;
	var h = date.getHours();
	h = h < 10 ? ('0' + h) : h;
	var minute = date.getMinutes();
	// var second = date.getSeconds();
	minute = minute < 10 ? ('0' + minute) : minute;
	// second = second < 10 ? ('0' + second) : second;
	var hours = date.getHours();
	var timeValue = "" + ((hours >= 12) ? "下午 " : "上午 ")
	var now = new Date();
	var nowy = now.getFullYear();
	var nowm = now.getMonth() + 1;
	var nowd = now.getDate();
	var nowd_z = nowd + 1;
	nowm = nowm < 10 ? ('0' + nowm) : nowm;
	nowd = nowd < 10 ? ('0' + nowd) : nowd;
	var returnStr = "";
	if (nowm == m && nowy == y && nowd == d) {
		returnStr = timeValue + h + ':' + minute;
	}
	if (nowm == m && nowy == y && nowd_z == d) {
		returnStr = " 明天  " + h + ':' + minute;
	}
	if (nowy == y && nowd_z != d && nowd != d) {
		returnStr = m + '/' + d + ' ' + h + ':' + minute;
	}
	if (nowy != y) {
		returnStr = y + '/' + m + '/' + d + ' ' + h + ':' + minute;
	}

	return returnStr;
}

export function GetDateStr(AddDayCount) {
	var dd = new Date();
	dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
	var y = dd.getFullYear();
	var m = dd.getMonth() + 1; //获取当前月份的日期
	var d = dd.getDate();
	return y + "/" + m + "/" + d;
}

export function getWeek(val) {
	var now = new Date(val);
	var day = now.getDay();
	var weeks = new Array('周日', '周一', '周二', '周三', '周四', '周五', '周六');
	var week = weeks[day];
	return week;
}

// 获取num天后的时间
// getDayCode(1,/) -- 明天 用 / 分隔
export function getNumDay(num, str) {
	let today = new Date();
	let nowTime = today.getTime();
	let ms = 24*3600*1000*num;
	today.setTime(parseInt(nowTime + ms));
	let oYear = today.getFullYear();
	let oMoth = (today.getMonth() + 1).toString();
	if (oMoth.length <= 1) oMoth = '0' + oMoth;
	let oDay = today.getDate().toString();
	if (oDay.length <= 1) oDay = '0' + oDay;
	return oYear + str + oMoth + str + oDay;
}

// 计算时间差 天 返回格式为xx天
export function getDisparityDay(faultDate, completeTime) {
	let stime = Date.parse(new Date(faultDate));
	let etime = Date.parse(new Date(completeTime));

	// 两个时间戳相差的毫秒数
	let usedTime = etime - stime;
	// 计算相差的天数
	let days = Math.floor(usedTime / (24 * 3600 * 1000));
	// 计算天数后剩余的毫秒数
	let leave1 = usedTime % (24 * 3600 * 1000);
	return leave1 > 0 ? days + 1 : days
}


// 计算时间差 月 返回格式为xx月
export function getDisparityMonth(faultDate, completeTime) {
	let months = (completeTime.substring(0,4) - faultDate.substring(0,4)) * 12 + (completeTime.substring(5,7) - faultDate.substring(5,7))

	if(Date.parse(faultDate.substring(0,7) + completeTime.substring(7)) > Date.parse(new Date(faultDate))) {
		return months + 1
	}
	return months
}
// 计算时间差 年 返回格式为xx年
export function getDisparityYear(faultDate, completeTime) {
	let stime = faultDate.substring(0,4)
	let etime = completeTime.substring(0,4)
	let years = etime - stime
	if(Date.parse(stime + completeTime.substring(4)) > Date.parse(new Date(faultDate))) {
		return years + 1
	}
	return years
}
