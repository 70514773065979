import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/transDate.js'
import './plugins/axios'
import './plugins/filters'

import 'flex.css'
import './assets/css/base.scss'
import './assets/icons/icon'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './i18n'
import validatorPlugin from "./global/validator"

import Print from 'vue-print-nb' //打印

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import VCharts from 'v-charts'// 图表库 参考地址：https://v-charts.js.org/
require('./mock');  //mock数据
Vue.use(ElementUI);

Vue.use(Print);
// 手动页面标签
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.name) {
    document.title = to.name
  }
  next()
})
// 页面跳转，自动回到头部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'ED8b21c20913a4a366e1759665460369'
})
Vue.use(VideoPlayer)
Vue.use(VCharts)
Vue.use(validatorPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

