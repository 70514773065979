import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "main",
    component: () => import("@/modules/public/MainLayoutPC.vue"),
    redirect: '/home',
    children: [
      {
        path: "/",
        name: "首页",
        component: () => import("@/modules/public/index.vue")
      },
      {
        path: "/news",
        name: "新闻中心",
        component: () => import("@/modules/public/news/newsList.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/about",
        name: "关于我们",
        component: () => import("@/modules/public/about/about.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/newsDetails",
        name: "新闻详情",
        component: () => import("@/modules/public/news/newsDetails.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/contact",
        name: "联系我们",
        component: () => import("@/modules/public/contact/contact.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/iot_gateways",
        name: "物联网网关",
        component: () => import("@/modules/public/iotGateways/iotGateways.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/api",
        name: "智能API",
        component: () => import("@/modules/public/api/api.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/property_system",
        name: "智能物业系统",
        component: () => import("@/modules/public/propertySystem/propertySystem.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/digital_center",
        name: "数字统筹中心",
        component: () => import("@/modules/public/digitalCenter/digitalCenter.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/community_solution",
        name: "智慧社区",
        component: () => import("@/modules/public/communitySolution/communitySolution.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/park_solution",
        name: "智慧园区",
        component: () => import("@/modules/public/parkSolution/parkSolution.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/office_building",
        name: "智慧写字楼",
        component: () => import("@/modules/public/officeBuilding/officeBuilding.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/hotel_solution",
        name: "智慧酒店",
        component: () => import("@/modules/public/hotelSolution/hotelSolution.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/barracks_solution",
        name: "智慧军营",
        component: () => import("@/modules/public/barracksSolution/barracksSolution.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/join_property",
        name: "加入物业",
        component: () => import("@/modules/public/joinProperty/joinProperty.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/city_partner",
        name: "成为城市合伙人",
        component: () => import("@/modules/public/cityPartner/cityPartner.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/become_businessman",
        name: "成为商家",
        component: () => import("@/modules/public/becomeBusinessman/becomeBusinessman.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/nannyList",
        name: "保姆服务",
        component: () => import("@/modules/public/service/nannyList.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/cleanKeepingList",
        name: "保洁服务",
        component: () => import("@/modules/public/service/cleanKeepingList.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/housingList",
        name: "房屋维护",
        component: () => import("@/modules/public/service/housingList.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/plumbingList",
        name: "管道疏通",
        component: () => import("@/modules/public/service/plumbingList.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/serviceDetails",
        name: "服务详情",
        component: () => import("@/modules/public/service/serviceDetails.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      },
      {
        path: "/agreement",
        name: "用户协议",
        component: () => import("@/modules/public/agreement/agreement.vue"),
        meta:{
          banner:true,//banner在该页面不显示，取了反值
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

//路由拦截
// router.beforeEach((to, from, next) => {
//   if (to.meta.isLogin) {
//       /* 判断该路由是否需要登录权限 */
//       if (store.state.accessToken) {
//           /* 通过vuex state获取当前的token是否存在 */
//           next();
//       } else {
//           next({
//               path: '/',
//               query: {
//                   redirect: to.fullPath
//               } /* 将跳转的路由path作为参数，登录成功后跳转到该路由 */
//           })
//       }
//   } else {
//       next();
//   }
// });
export default router;
