const Mock = require('mockjs');
//格式： Mock.mock( url, post/get , 返回的数据)；
//auth
//保姆
Mock.mock(
  "/service/search_nanny",
  "post",
  require("./json/service/search_nanny")
);

//保洁
Mock.mock(
  "/service/search_clean_keeping",
  "post",
  require("./json/service/search_clean_keeping")
);

//房屋维护
Mock.mock(
  "/service/search_housing",
  "post",
  require("./json/service/search_housing")
);

//管道
Mock.mock(
  "/service/search_plumbing",
  "post",
  require("./json/service/search_plumbing")
);

//最热
Mock.mock(
  "/service/search_hot",
  "post",
  require("./json/service/search_hot")
);

//最新
Mock.mock(
  "/service/search_latest",
  "post",
  require("./json/service/search_latest")
);

//畅销
Mock.mock(
  "/service/search_best_selling",
  "post",
  require("./json/service/search_best_selling")
);

//详情
let search_info= function (options) {
  let code = JSON.parse(options.body).service_code
  let list = JSON.parse(JSON.stringify(require("./json/service/search_info")))
  let arr = list.data.filter(item => {
    return item.service_code == code
  })
  list.data = arr
  return list
}
Mock.mock(
  "/service/search_info",
  "post",
  search_info
);
