<template>
  <div id="app" v-loading="loading">
    <keep-alive>
      <router-view v-if="isRouterAlive"/>
    </keep-alive>
  </div>
</template>
<script>
  import {mapState} from 'vuex'
  export default {
    provide () {
      return {
        reload: this.reload
      }
    },
    computed: {
      ...mapState(['loading'])
    },
    data() {
      return {
        isRouterAlive: true
      }
    },

    methods: {
      reload () {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      }
    },
  }
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif, "微软雅黑";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
/*.el-input__inner {*/
/*  padding: 0 25px !important;*/
/*}*/
body{
  margin: 0px !important;
}
 /*上传图片*/
    .logoimg {
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
        margin: 0 auto;
    }

    .avatar-uploader {
        text-align: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #8c939d;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px !important;
        height: 150px !important;
        line-height: 150px !important;
        text-align: center;
    }

    .avatar {
        width: 150px;
        height: 150px;
        display: block;
    }

    .el-checkbox.is-bordered {
        height: 85px !important;
    }

    /*地图插件 覆盖*/
    .BMap_CityListCtrl {
        top: 40px !important
    }

    .anchorTR {
        right: 80px !important;
    }

    .BMap_stdMpCtrl {
        right: 10px !important;
    }

    .citylist_popup_main .city_content_top {
        height: 40px !important;
    }

    .v-modal {
        position: initial !important;
    }

    .el-form--label-top .el-form-item__label {
        padding: 0 0 0px 0 !important;
    }

    /*步骤条*/
    .el-step__title.is-process {
        font-weight: bold;
        color: #67C23A !important;
    }

    .el-step__head.is-process {
        color: #67C23A !important;
    }

    .is-process .el-step__icon.is-text {
        border-color: #67C23A !important;
    }

    /*.el-checkbox__label {*/
    /*display: none!important;*/
    /*}*/
    .el-step__title {
        font-size: 14px !important;
        line-height: 25px !important;
    }

    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
    .el-collapse {
        border-top: none!important;
        border-bottom: 1px solid #EBEEF5;
    }
    /*员工详情 右边部分*/
    .staffRight{
        margin-right: -20px;
        position: relative;
        height: 100%;
    }
    .rightTop{
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        background: transparent;
        z-index: 10;
    }
    .rightTopTitle{
        width: 100%;
        float: left;
        border-bottom: 1px solid #eee;
        background: white;
    }
    .rightTopTitle p{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .rightTopTitle p span{
        color:#b78234;
        font-size: 16px;
        margin-left: 10px
    }
    .staffMain{
        padding-top: 60px;
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .turnBackBtn{
        border: none !important;
        padding: 0px !important;
        margin: 0px!important;
        width: 100% !important;
        background: none!important;
    }
    .el-input--mini .el-input__inner {
        height: 30px !important;
        line-height: 30px !important;
    }
    /*弹窗*/
    .newDialog{
        max-height: 75vh;
        overflow: auto;
        width: 100%;
    }
    .newDialogMain{
        width: 98%;
        float:left;
    }
    .el-dialog{
        margin-top: 8vh!important;
        margin-bottom: 0px!important;
    }

    .el-input-number--small .el-input-number__increase, .el-input-number--small .el-input-number__decrease {
        width: 25px!important;
        font-size: 13px;
    }
	.pointerclass{
		cursor: pointer;
	}
.sys-user--list {
  padding: 16px;
  background-color: #ffffff;
  float: left;
  width: 100%;
}

body .el-table th.gutter{
  display: table-cell!important;
}

  #app /deep/ .el-loading-mask {
    z-index: 99999;
  }

.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.newBtn:active {
  color: #fff;
  border-color: #FF2C2C;
  outline: 0;
}
 .newBtn:focus, .newBtn:hover {
   color: #fff!important;
   border-color: #FF2C2C!important;
   background-color: #FF2C2C!important;
 }
 .unwindBtn{
   padding: 8px 10px !important;
 }
.unwindBtn i{
  font-size: 20px !important;
}
.unwindBtn:active {
  color: #fff;
  border-color: #FF2C2C;
  outline: 0;
}
.unwindBtn:focus, .unwindBtn:hover {
  color: #fff!important;
  border-color: #FF2C2C!important;
  background-color: #FF2C2C!important;
}
.el-submenu__title{
  text-align: center !important;
}
.el-tabs__active-bar {
  background-color: #f90!important;
}
.el-tabs__item:hover {
  color: #f90!important;
  cursor: pointer;
}
.el-tabs__item.is-active {
  color: #f90!important;
}

/*标题*/
.bigTitle{
  text-align: center;
  font-size: 28px;
  font-weight: 400;
}
.lineTitle{
  text-align: center;
  background: #F66012;
  width: 22px;
  height: 2px;
  margin: 10px auto!important;
}
.enTitle{
  text-align: center;
  font-size: 12px;
  color: #999999;
  text-transform: uppercase
}

.indexPro{
  width: 100%;
  height: 350px
}
.proBg{
  min-height: 300px
}
.introduce{
  text-align: left;
  font-size: 16px;
  padding-left: 2%;
  padding-right: 2%;
  line-height: 30px
}

/*margin*/
/*top*/
.margin_t_0{
  margin-top: 0px;
}
.margin_t_5{
  margin-top: 5px;
}
.margin_t_10{
  margin-top: 10px;
}
.margin_t_15{
  margin-top: 15px;
}
.margin_t_20{
  margin-top: 20px;
}
.margin_t_25{
  margin-top: 25px;
}
.margin_t_30{
  margin-top: 30px;
}
.margin_t_35{
  margin-top: 35px;
}
.margin_t_40{
  margin-top: 40px;
}
.margin_t_45{
  margin-top: 45px;
}
.margin_t_50{
  margin-top: 50px;
}
.margin_t_55{
  margin-top: 55px;
}
.margin_t_60{
  margin-top: 60px;
}
.margin_t_65{
  margin-top: 65px;
}
.margin_t_70{
  margin-top: 70px;
}
.margin_t_75{
  margin-top: 75px;
}
.margin_t_80{
  margin-top: 80px;
}
.margin_t_85{
  margin-top: 85px;
}
.margin_t_90{
  margin-top: 90px;
}
.margin_t_95{
  margin-top: 95px;
}
.margin_t_100{
  margin-top: 100px;
}
/*right*/
.margin_r_0{
  margin-right: 0px;
}
.margin_r_5{
  margin-right: 5px;
}
.margin_r_10{
  margin-right: 10px;
}
.margin_r_15{
  margin-right: 15px;
}
.margin_r_20{
  margin-right: 20px;
}
.margin_r_25{
  margin-right: 25px;
}
.margin_r_30{
  margin-right: 30px;
}
.margin_r_35{
  margin-right: 35px;
}
.margin_r_40{
  margin-right: 40px;
}
.margin_r_45{
  margin-right: 45px;
}
.margin_r_50{
  margin-right: 50px;
}
.margin_r_55{
  margin-right: 55px;
}
.margin_r_60{
  margin-right: 60px;
}
.margin_r_65{
  margin-right: 65px;
}
.margin_r_70{
  margin-right: 70px;
}
.margin_r_75{
  margin-right: 75px;
}
.margin_r_80{
  margin-right: 80px;
}
.margin_r_85{
  margin-right: 85px;
}
.margin_r_90{
  margin-right: 90px;
}
.margin_r_95{
  margin-right: 95px;
}
.margin_r_100{
  margin-right: 100px;
}
/*bottom*/
.margin_b_0{
  margin-bottom: 0px;
}
.margin_b_5{
  margin-bottom: 5px;
}
.margin_b_10{
  margin-bottom: 10px;
}
.margin_b_15{
  margin-bottom: 15px;
}
.margin_b_20{
  margin-bottom: 20px !important;
}
.margin_b_25{
  margin-bottom: 25px;
}
.margin_b_30{
  margin-bottom: 30px;
}
.margin_b_35{
  margin-bottom: 35px;
}
.margin_b_40{
  margin-bottom: 40px;
}
.margin_b_45{
  margin-bottom: 45px;
}
.margin_b_50{
  margin-bottom: 50px;
}
.margin_b_55{
  margin-bottom: 55px;
}
.margin_b_60{
  margin-bottom: 60px;
}
.margin_b_65{
  margin-bottom: 65px;
}
.margin_b_70{
  margin-bottom: 70px;
}
.margin_b_75{
  margin-bottom: 75px;
}
.margin_b_80{
  margin-bottom: 80px;
}
.margin_b_85{
  margin-bottom: 85px;
}
.margin_b_90{
  margin-bottom: 90px;
}
.margin_b_95{
  margin-bottom: 95px;
}
.margin_b_100{
  margin-bottom: 100px;
}
/*left*/
.margin_l_0{
  margin-left: 0px;
}
.margin_l_5{
  margin-left: 5px;
}
.margin_l_10{
  margin-left: 10px;
}
.margin_l_15{
  margin-left: 15px;
}
.margin_l_20{
  margin-left: 20px;
}
.margin_l_25{
  margin-left: 25px;
}
.margin_l_30{
  margin-left: 30px;
}
.margin_l_35{
  margin-left: 35px;
}
.margin_l_40{
  margin-left: 40px;
}
.margin_l_45{
  margin-left: 45px;
}
.margin_l_50{
  margin-left: 50px;
}
.margin_l_55{
  margin-left: 55px;
}
.margin_l_60{
  margin-left: 60px;
}
.margin_l_65{
  margin-left: 65px;
}
.margin_l_70{
  margin-left: 70px;
}
.margin_l_75{
  margin-left: 75px;
}
.margin_l_80{
  margin-left: 80px;
}
.margin_l_85{
  margin-left: 85px;
}
.margin_l_90{
  margin-left: 90px;
}
.margin_l_95{
  margin-left: 95px;
}
.margin_l_100{
  margin-left: 100px;
}
/*all*/
.margin_all_0{
  margin: 0px;
}
.margin_all_5{
  margin: 5px;
}
.margin_all_10{
  margin: 10px;
}
.margin_all_15{
  margin: 15px;
}
.margin_all_20{
  margin: 20px;
}
.margin_all_25{
  margin: 25px;
}
.margin_all_30{
  margin: 30px;
}
.margin_all_35{
  margin: 35px;
}
.margin_all_40{
  margin: 40px;
}
.margin_all_45{
  margin: 45px;
}
.margin_all_50{
  margin: 50px;
}
.margin_all_55{
  margin: 55px;
}
.margin_all_60{
  margin: 60px;
}
.margin_all_65{
  margin: 65px;
}
.margin_all_70{
  margin: 70px;
}
.margin_all_75{
  margin: 75px;
}
.margin_all_80{
  margin: 80px;
}
.margin_all_85{
  margin: 85px;
}
.margin_all_90{
  margin: 90px;
}
.margin_all_95{
  margin: 95px;
}
.margin_all_100{
  margin: 100px;
}

.textP{
  font-size: 16px;
  margin-bottom: 20px;
  text-indent: 2em
}
.textP span{
  color: #2564f4;
  font-size: 22px;
  font-weight: 100
}
.serviceP{
  text-align: center;
  font-size: 28px;
}
.serviceS{
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  font-weight: 100;
}
.colorBlue{
  color: #2564f4;
}
.serviceM{
  font-size: 14px;
  text-align: left;
  color: #333;
  margin-top: 10px;
}
.project-title{
  font-size: 26px;
  color: #666;
  text-align: center;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 100;
}
.project-title i{
  font-size: 26px;
  margin-right: 10px;
}

.moreBg{
  width: 50px;
  margin: auto;
}
.more{
  text-align: center;
  padding:0px 5px;
  border: 1px solid #999;
  border-radius: 50px;
  width: 50px;
  line-height: 25px;
  float: right;
  cursor: pointer;
  color: #999;
  margin-top: 12px;
  font-size: 12px;
}
.more:active {
  color: #fff;
  border-color: #f90;
  outline: 0;
}
.more:focus, .more:hover {
  color: #fff!important;
  border-color: #f90!important;
  background-color: #f90!important;
}
.el-card__body {
  padding: 10px!important;
}
</style>
