const Validator = {
    // 验证不为空
    notEmpty() {
        var notEmpty = (rule, value, callback) => {
            if (!(value) && value != 0) {
                callback(new Error('必填项不能为空'));
            } else {
                if (!value.toString().trim()) {
                    callback(new Error('必填项不能为空'));
                }else {
                    callback();
                }
            }
        }
        return notEmpty
    },

    // 验证名字
    validateName() {
        let validateName = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('名字不能为空'));
            } else {
                callback();
            }
        }
        return validateName
    },
    //
    // // 验证名字
    // validateName() {
    //     let validateName = (rule, value, callback) => {
    //         if (!/\S/.test(value)) {
    //             callback(new Error('名字不能为空'));
    //         } else if (!/^[\u4E00-\u9FA5]{2,20}$/.test(value)) {
    //             callback(new Error('请填写中文名字！长度在2-20之间'));
    //         } else if (!value.match("^[a-zA-Z0-9_\u4e00-\u9fa5]+$")) {
    //             callback(new Error('请不要填写特殊符号！'));
    //         } else {
    //             callback();
    //         }
    //     }
    //     return validateName
    // },

    // 验证用户名
    validateUserName() {
        let validateUserName = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('名字不能为空'));
            }else{
                callback();
            }
        }
        return validateUserName
    },
    // // 验证用户名
    // validateUserName() {
    //     let validateUserName = (rule, value, callback) => {
    //         if (!/\S/.test(value)) {
    //             callback(new Error('名字不能为空'));
    //         } else if (!value.match("^[a-zA-Z0-9_\u4e00-\u9fa5]+$")) {
    //             callback(new Error('请不要填写特殊符号！'));
    //         }else if (!value.match("^[a-zA-Z0-9_-]{4,16}$")) {
    //             callback(new Error('由数字，字母，下划线组合，长度4-16'));
    //         } else{
    //             callback();
    //         }
    //     }
    //     return validateUserName
    // },

    // 密码
    validatePassword() {
        let validatePassword = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('密码不能为空'))
            } else {
                callback()
            }
        }
        return validatePassword
    },
    // 密码设置
    validatePassword2() {
        let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
        let validatePassword = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('密码不能为空'))
            } else if(!reg.test(value)) {
                callback(new Error('密码至少包含 数字和英文，长度6-16'))
            }else {
                callback()
            }
        }
        return validatePassword
    },

    //确认密码
    validateComfirmPassword(password) {
        let validateComfirmPassword = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('确认密码不能为空'))
            } else if (password !== value) {
                callback(new Error('确认密码与密码输入不一致'))
            } else {
                callback()
            }
        }
        return validateComfirmPassword
    },

    // 验证年龄
    validateAge() {
        let validateAge = (rule, value, callback) => {
            const toNumberVal = Number(value);
            if (!/\S/.test(value)) {
                callback(new Error('年龄不允许为空'));
            } else if (isNaN(toNumberVal)) {
                callback(new Error('年龄为数值类型'));
            } else if (!(toNumberVal > 0 && toNumberVal <= 120)) {
                callback(new Error('年龄范围应该大于一岁且小于等于120岁'));
            } else {
                callback();
            }
        }
        return validateAge
    },

    // 验证性别
    validateSex() {
        let validateSex = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('性别不允许为空'));
            }
            {
                callback();
            }
        }
        return validateSex
    },


    /*手机号字符串校验，返回true/false*/
    isPhone(str) {
        const reg = /^1[3456789]\d{9}$/;
        return reg.test(str)
    },
    /*手机规则校验器*/
    validatePhone() {
        let validPhone = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('请输入手机号码'))
            } else if (!Validator.isPhone(value)) {
                callback(new Error('请输入正确的11位手机号码'))
            } else {
                callback()
            }
        }
        return validPhone
    },

    /*手机规则校验器(为空可通过)*/
    validatePhone2() {
        let validPhone = (rule, value, callback) => {

            if (!value) {
                callback()
            }else if (!Validator.isPhone(value)) {
                callback(new Error('请输入正确的11位手机号码'))
            }else {
                callback()
            }
        }
        return validPhone
    },
    //验证邮箱
    validateEmail() {
        let checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!/\S/.test(value)) {
                return callback(new Error('邮箱不能为空'))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error('请输入正确的邮箱格式'))
                }
            }, 100)
        }
        return checkEmail
    },
    //验证邮箱(为空可通过)
    validateEmail2() {
        let checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                callback()
            } else if (mailReg.test(value)) {
                callback()
            } else {
                callback(new Error('请输入正确的邮箱格式'))
            }
        }
        return checkEmail
    },
    /*请输入数字*/
    validateNumber() {
        let validateNumber = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            } else if (isNaN(Number(value))) {
                callback(new Error('该项必须为数字'))
            } else {
                callback()
            }
        }
        return validateNumber
    },
    /*请输入正整数*/
    validateInt() {
        let isvalidateInteger = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            } else if (!Number.isInteger(Number(value))) {
                callback(new Error('请输入正整数'))
            }else if ((Number(value))<0) {
                callback(new Error('请输入正整数'))
            } else {
                callback()
            }
        }
        return isvalidateInteger
    },
    /*请输入大于0正整数*/
    validateIntZero() {
        let isvalidateInteger = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            } else if (!Number.isInteger(Number(value))) {
                callback(new Error('请输入大于0的正整数'))
            }else if ((Number(value))<1) {
                callback(new Error('请输入大于0的正整数'))
            } else {
                callback()
            }
        }
        return isvalidateInteger
    },
    /*最小值*/
    validateMin(min) {
        let validateMin = (rule, value, callback) => {
            let reg=/^[(-9)-9]+.?[(-9)-9]*$/
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            }else if (!reg.test(value)){
                callback(new Error('该项必须为数字'))
            }else if (isNaN(Number(value))) {
                callback(new Error('该项必须为数字'))
            } else if (Number(value) < min) {
                callback(new Error('该项最小值为' + min))
            } else {
                callback()
            }
        }
        return validateMin
    },
    /*最大值*/
    validateMax(max) {
        let validateMax = (rule, value, callback) => {
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            } else if (Number(value) > max) {
                callback(new Error('该项最大值为' + max))
            } else {
                callback()
            }
        }
        return validateMax
    },
    //验证银行卡号
    validateBankNo() {
        let validateBankNo = (rule, value, callback) => {
            let bankno = value.toString().trim()
            let num = /^\d*$/; //全数字
            if (!num.exec(bankno)) {
                callback(new Error('银行卡号必须全为数字'))
            }else if (bankno.length < 16 || bankno.length > 19) {
                callback(new Error('银行卡号长度必须在16到19之间'))
            }else {
                callback()
            }
        }
        return validateBankNo
    },

    /*车牌号码*/
    validateCarNo() {
        let validateCarNo = (rule, value, callback) => {
            let reg=/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
            if (!/\S/.test(value)) {
                callback(new Error('必选项不能为空'))
            }else if (!reg.test(value)){
                callback(new Error('请检查车牌号码是否正确填写！'))
            } else {
                callback()
            }
        }
        return validateCarNo
    },
    /*身份证号验证*/
    validateIDNo() {
        let validateIDNo = (rule, value, callback) => {
            let reg=/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
            if(!reg.test(value)){
                callback(new Error('请检查身份证号码是否正确填写！'))
            } else {
                callback()
            }
        }
        return validateIDNo
    },
    /*身份证号验证(为空可通过)*/
    validateIDNo2() {
        let validateIDNo = (rule, value, callback) => {
            let reg=/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/

            if (!value) {
                callback()
            }
            else if(!reg.test(value)){
                callback(new Error('请检查身份证号码是否正确填写！'))
            } else {
                callback()
            }
        }
        return validateIDNo
    },
    /*英文名验证*/
    validateEName() {
        let validateEName = (rule, value, callback) => {
            let reg=/^[a-zA-Z0-9]+\s?[.·\-()a-zA-Z]*[a-zA-Z]+$/
            if(!reg.test(value)){
                callback(new Error('请检查英文名是否正确填写！'))
            } else {
                callback()
            }
        }
        return validateEName
    },
}

// 对Validator的实例重新封装成一个plugin ,方便 Vue.use(xxxx)
export default {
    install: function (Vue) {
        Object.defineProperty(Vue.prototype, "$valid", {value: Validator});
    }
};
