/**
 * Author:Rich
 * Create Time:2019-08-11
 * FileName:   utils.js
 * Function: 工具类
 **/

export default class Utils {
    /**
     * 通过传入的children字段名，将多层级的对象打平为一个数组
     * @param {T} source
     * @param {string} children
     * @returns {T[]}
     * 例如  [{id: 1, children: [{id: 2}]}] => [{id: 1}, {id: 2}]
     */
    flatObject(source, children = 'children') {
        const re = []
        const clone = JSON.parse(JSON.stringify(source))
        this.flatObjectDo(clone, children, re)
        return re
    }

    flatObjectDo(source, children, re, parentId) {
        source.forEach((s) => {
            if (parentId) {
                s.parentId = parentId
            }
            re.push(s)
            if (s[children]) {
                this.flatObjectDo(s[children], children, re, s.id)
                delete s[children]
            }
        })
    }

    closeObject(source) {
        let re = []
        source.forEach((s, index) => {
            s.children = []
            // if (source.length > 0){
            if (s.parentId == 0) {
                re.push(s)
                // source.splice(index,1)
            }
            // }
        })
        source.forEach((s) => {
            // if (source.length > 0){
            if (s.parentId !== 0) {
                re.forEach((r) => {
                    if (r.id == s.parentId) {
                        r.children.push(s)
                        // source.splice(index,1)
                    }
                })
            }
            // }
        })
        return re
    }

    /**
     * 将一维的扁平数组转换为多层级对象
     * @param  {[type]} list 一维数组，数组中每一个元素需包含id和parent_id两个属性
     * @return {[type]} tree 多层级树状结构
     */
    // closeObject(data) {
    //     console.log(data);
    //     let convertData = []
    //     data.forEach((item,index)=>{
    //         if(item.parentId == 0){
    //             convertData.push(item)
    //             this.convertChildren(data,item,item.id)
    //         }
    //     })
    //     return convertData
    // }
    //
    // convertChildren(arr,parentItem,parentId){
    //     parentItem.children = parentItem.children ? parentItem.children : []
    //     arr.forEach(item=>{
    //         if (item.parentId == parentId){
    //             parentItem.children.push(item)
    //             this.convertChildren(arr,item,item.id)
    //         }
    //     })
    //     return parentItem.children
    // }

    treeDataTranslate(data, id = 'id', pid = 'parentId') {
        let res = []
        let temp = {}
        for (let i = 0; i < data.length; i++) {
            temp[data[i][id]] = data[i]
        }
        for (let k = 0; k < data.length; k++) {
            if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
                if (!temp[data[k][pid]]['children']) {
                    temp[data[k][pid]]['children'] = []
                }
                if (!temp[data[k][pid]]['_level']) {
                    temp[data[k][pid]]['_level'] = 1
                }
                data[k]['_level'] = temp[data[k][pid]]._level + 1
                temp[data[k][pid]]['children'].push(data[k])
            } else {
                res.push(data[k])
            }
        }
        data = []
        return res
    }

    sortBy(attr, rev) {
        //第二个参数没有传递 默认升序排列
        if (rev == undefined) {
            rev = 1;
        } else {
            rev = (rev) ? 1 : -1;
        }

        return function (a, b) {
            a = a[attr];
            b = b[attr];
            if (a < b) {
                return rev * -1;
            }
            if (a > b) {
                return rev * 1;
            }
            return 0;
        }
    }

    debounce(func, wait, immediate) {
        let timeout, args, context, timestamp, result

        const later = function() {
            // 据上一次触发时间间隔
            const last = +new Date() - timestamp

            // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last)
            } else {
                timeout = null
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args)
                    if (!timeout) context = args = null
                }
            }
        }

        return function(...args) {
            context = this
            timestamp = +new Date()
            const callNow = immediate && !timeout
            // 如果延时不存在，重新设定延时
            if (!timeout) timeout = setTimeout(later, wait)
            if (callNow) {
                result = func.apply(context, args)
                context = args = null
            }

            return result
        }
    }


   easeInOutQuad (t, b, c, d) {
        t /= d / 2
        if (t < 1) {
            return c / 2 * t * t + b
        }
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
    }

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
    requestAnimFrame() {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function(callback) { window.setTimeout(callback, 1000 / 60) }
    }

// because it's so fucking difficult to detect the scrolling element, just move them all
    move(amount) {
        document.documentElement.scrollTop = amount
        document.body.parentNode.scrollTop = amount
        document.body.scrollTop = amount
    }

    position() {
        return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop
    }

    scrollTo(to, duration, callback) {
        const start = this.position()
        const change = to - start
        const increment = 20
        let currentTime = 0
        duration = (typeof (duration) === 'undefined') ? 500 : duration
        var animateScroll = function() {
            // increment the time
            currentTime += increment
            // find the value with the quadratic in-out easing function
            var val = this.easeInOutQuad(currentTime, start, change, duration)
            // move the document.body
            this.move(val)
            // do the animation unless its over
            if (currentTime < duration) {
                this.requestAnimFrame(animateScroll)
            } else {
                if (callback && typeof (callback) === 'function') {
                    // the animation is done so lets callback
                    callback()
                }
            }
        }
        animateScroll()
    }

}
