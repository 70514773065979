import Vue from 'vue'

const filterList = {
  filterMoney: (number, places, thousand, decimal) => {
    number = number || 0;
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    thousand = thousand || ",";
    decimal = decimal || ".";
    let negative = number < 0 ? "-" : "",
      i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
    return negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "") + '元';
  },

  imgfilter: (imageUrl) => {
    if (!imageUrl) {
      return require('@/assets/noimg.jpg')
    } else {
      return process.env.VUE_APP_DEV_URL + imageUrl
    }
  },

  filterSex: (val) => {
    return val === 'M' ? '男' : (val === 'F' ? '女' : '暂无')
  },

  filterTitle: (val) => {
    let str = ''
    switch(val) {
      case 1:
        str = '队长'
        break
      case 2:
        str = '副队长'
        break
      case 0:
        str = '队员'
        break
    }
    return str
  },
}

Object.keys(filterList).map((item) => {
  Vue.filter(item, filterList[item])
})
