import Vue from 'vue'
import Vuex from 'vuex'
import utils from '@/utils'
import router from '@/router'
import i18n from '../i18n'
Vue.use(Vuex)

export default new Vuex.Store({
    // 设置属性
    state: {
        util: new utils(),
        isLogin: false,
        usercode: localStorage.getItem('usercode'),//用户编码
        avatar: localStorage.getItem('avatar'),//用户编码
        // usercode: "U1565941285414",//用户编码
        username: localStorage.getItem('username'),//用户名称
        usercname: localStorage.getItem('usercname'),//用户名称
        // username: "admin",//用户名称
        orgcode: localStorage.getItem('orgcode'),//机构编码
      realorgcode: localStorage.getItem('realorgcode'),//真实机构编码

        depcode: localStorage.getItem('depcode'),//部门编码

        projectCode:localStorage.getItem('searchProjectcode'), //项目编码

        language: localStorage.getItem('language'),//当前语言

        buildingCode: '', //楼栋编码

        floorCode:'', //楼层编码ID

        roomCode:'', //房间编码ID


        // orgcode: "O1565936242133",//机构编码
        accessToken: localStorage.getItem('accessToken'), // accessToken
        refreshToken: localStorage.getItem('refreshToken'), // refreshToken
        currentSys: localStorage.getItem('currentSys'), // currentSys
        roles: localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : [], //角色集,注意localStorage虽然用JSON.stringify当做array存入,必须用JSON.parse取出
        orgcodeList: localStorage.getItem('orgcodeList') ? JSON.parse(localStorage.getItem('orgcodeList')) : [], //数据权限列表
        // orgcode_list: [], //数据权限列表
        rooms: JSON.parse(localStorage.getItem('rooms')),  //所在房间集合
        // roles: [], //角色集,注意localStorage虽然用JSON.stringify当做array存入,必须用JSON.parse取出
        menus: [],  //菜单
        menuTabs: localStorage.getItem('menuTabs') ? JSON.parse(localStorage.getItem('menuTabs')) : [], // tabs
        selectedTab: localStorage.getItem('selectedTab') ? localStorage.getItem('selectedTab') : '0', // 当前激活的tab
        Theme: {
            currentTheme: {
                name: '橘黄色主题',
                baseColor: 'rgba(240, 133, 25, 1)', // 主题基础色
                className: 'orangeTheme',
                headerFontColor: '#ffffff',
                navBgColor: '#414141', // 菜单背景颜色
                navTextColor: '#fdfefe', // 菜单字体颜色
                navActiveTextColor: 'rgba(240, 133, 25, 1)', // 菜单选中字体颜色
                bacgroundImage: null
            }
        },
        tmpMenus:[],
        flatMenus: [], //平铺的菜单
      loading: false,
    },

    // 获取属性的状态
    getters: {
        //获取登录状态
        isLogin: state => state.isLogin,
        //获取参数
        orgcode: state => state.orgcode,
        depcode: state => state.depcode,
        usercode: state => state.usercode,
        username: state => state.username,
        usercname: state => state.usercname,
        roles: state => state.roles,
        orgcodeList: state => state.orgcodeList,
        rooms: state => state.rooms,
        language: state => state.language,
        avatar: state => state.avatar
    },


    // 设置属性状态
    mutations: {

        //保存登录状态
        userStatus(state, flag) {
            state.isLogin = flag
        },

        //保存登录状态
        saveAvatar(state, avatar) {
            state.saveAvatar = avatar
        },

        //设置机构编码
        orgCode(state, orgcode) {
            state.orgcode = orgcode
        },

      realOrgCode(state, orgcode) {
        state.realorgcode = orgcode
      },

        //设置部门编码
        depCode(state, depcode) {
            state.depcode = depcode
        },

        //设置用户编码
        userCode(state, usercode) {
            state.usercode = usercode
        },

        //设置用户名称
        userName(state, username) {
            state.username = username
        },

        //设置用户中文名
        userCname(state, usercname) {
            state.usercname = usercname
        },

        //设置角色集
        sRole(state, roles) {
            state.roles = roles
        },

        //设置角色集
        sOrgcodeList(state, orgcodeList) {
            state.orgcodeList = orgcodeList
        },

        //设置房间集合
        rooms(state, rooms) {
            state.rooms = rooms
        },

        //设置accessToken
        accessToken(state, accessToken) {
            state.accessToken = accessToken
        },

        //设置refershToken
        refreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
        },

        updateCurrentSys(state,tmp) {
            state.currentSys = tmp
        },
        updateTmpMenu(state,tmp){
            state.tmpMenus = tmp
            const home = state.tmpMenus.find(m => m.permission_url === '/' || m.permission_url === '/index' || m.permission_url === '/home')
            if (state.menuTabs.length === 0) {
                state.menuTabs.push({
                    key: home.permission_level_code + '',
                    url: '/index',
                    menus: [{
                        ...home,
                        noClose: true
                    }]
                })
            }
            state.menus = state.tmpMenus
            // let arr = (state.util.flatObject(state.menus)).sort(state.util.sortBy('sortNo'))
            state.flatMenus = (state.util.flatObject(state.menus)).sort(state.util.sortBy('sortNo')).map(menu => ({
                ...menu,
                id: menu.id + ''
            }))
            return state.tmpMenus
        },
        //设置用户的菜单
        updateMenus(state) {
            // console.log(state.tmpMenus);
            // const home = state.tmpMenus.find(m => m.menuUrl === '/' || m.menuUrl === '/home')
            // if (state.menuTabs.length === 0) {
            //     state.menuTabs.push({
            //         key: home.id + '',
            //         url: '/home',
            //         menus: [{
            //             ...home,
            //             noClose: true
            //         }]
            //     })
            // }
            // state.menus = state.tmpMenus
            return state.tmpMenus
        },


        //设置用户的菜单栏
        updateTabs(state, params) {
            // console.log('111',params);
            if (params.key) {
                state.menuTabs.push(params)
            }
            localStorage.setItem('menuTabs', JSON.stringify(state.menuTabs))
        },

        //设置用户的选择菜单栏
        updateSeleteTab(state, params) {
            if (params) {
                state.selectedTab = params
                localStorage.setItem('selectedTab', params)
            }
        },

        // 打平的菜单，只有一个层级
        flatMenu(state) {
            // let arr = (state.util.flatObject(state.menus)).sort(state.util.sortBy('sortNo'))
            state.flatMenus = (state.util.flatObject(state.menus)).sort(state.util.sortBy('sortNo')).map(menu => ({
                ...menu,
                id: menu.id + ''
            }))
        },

        // 关闭tab
        removeTab(state, key) {
            if (!key) {
                key = state.selectedTab
            }
            // 从menuTabs里面删除tab
            const index = state.menuTabs.findIndex((o) => o.key === key)
            if (index >= 0) {
                state.menuTabs.splice(index, 1)
            }
            // delete state.noMenuTabsMap[key]
            // 如果删除的是当前激活的，要重新激活一个标签
            // 暂定激活下一个
            if (key === state.selectedTab) {
                const newIndex = state.menuTabs[index] ? index : index - 1
                // state.selectedTab = state.menuTabs[newIndex].key
                // 跳转url
                if (state.menuTabs.length === 0) {
                    state.selectedTab = null
                    router.push('/index')
                } else {
                    router.push(state.menuTabs[newIndex].url)
                }
            }
            localStorage.setItem('menuTabs', JSON.stringify(state.menuTabs))
        },

        // 从tabs移除多个tabs，一般用于批量关闭tab页的场景
        removeTabs(state, command) {
            const index = state.menuTabs.findIndex((tab) => tab.key === state.selectedTab)
            if (command === 'noActive') {
                state.menuTabs.splice(index + 1)
                if (index > 0) {
                    state.menuTabs.splice(1, index - 1)
                }
            } else if (command === 'all') {
                state.menuTabs = []
                router.push('/index')
            } else if (command === 'left') {
                if (index > 0) {
                    state.menuTabs.splice(1, index - 1)
                }
            } else if (command === 'right') {
                state.menuTabs.splice(index + 1)
            }
            localStorage.setItem('menuTabs', JSON.stringify(state.menuTabs))
        },

        //保存菜单更新
        menuSave(state, params) {
            for (let i = 0; i < state.flatMenus.length; i++) {
                if (params.id == state.flatMenus[i].id) {
                    state.flatMenus[i].applicationId = params.applicationId
                    state.flatMenus[i].icon = params.icon
                    state.flatMenus[i].isVisible = params.isVisible
                    state.flatMenus[i].menuType = params.menuType
                    state.flatMenus[i].menuUrl = params.menuUrl
                    state.flatMenus[i].name = params.name
                    state.flatMenus[i].sortNo = params.sortNo
                    if (params.parentId) {
                        state.flatMenus[i].parentId = params.parentId
                    } else {
                        state.flatMenus[i].parentId = 0
                    }
                }
            }
            state.tmpMenus = state.util.closeObject(state.flatMenus)
            return true
        },

        //保存菜单更新(新增)
        menuAdd(state, params) {
            let obj = {}
            obj.id = state.flatMenus.length
            obj.applicationId = params.applicationId
            obj.icon = params.icon
            obj.menuType = params.menuType
            obj.menuUrl = params.menuUrl
            obj.name = params.name
            if (params.parentId) {
                obj.parentId = params.parentId
            } else {
                obj.parentId = 0
            }

            obj.sortNo = params.sortNo
            obj.isVisible = 1
            console.log(obj);
            state.flatMenus.push(obj)
            state.tmpMenus = state.util.closeObject(state.flatMenus)
            return true
        },

        menuDel(state, id) {
            for (let i = state.flatMenus.length - 1; i > -1; i--) {
                if (id == state.flatMenus[i].id) {
                    state.flatMenus.splice(i, 1)
                }
                if (id == state.flatMenus[i].parentId) {
                    state.flatMenus.splice(i, 1)
                }
            }
            state.tmpMenus = state.util.closeObject(state.flatMenus)
        },

        //退出登录清空state状态
        clearall(state){
            state.accessToken=''
            state.refreshToken=''
            state.roles=''
            state.rooms=''
            state.orgcode=''
            state.depcode=''
            state.usercode=''
            state.username=''
            state.menuTabs=[]
            state.selectedTab = "0001"
            state.isLogin=false
        },

        //修改项目唯一ID
        changeProjectCode(state,code){
            state.projectCode = code;
            localStorage.setItem('projectCode',code)
        },
        //修改楼栋code
        changeBuildingCode(state,code){
            state.buildingCode = code
        },
        //修改楼层code
        changeFloorCode(state,code){
            state.floorCode = code
        },
        //修改房间code
        changeRoomCode(state,code){
            state.roomCode = code
        },

        //设置语言
        localLanguage(state, language) {
            state.language = language
            i18n.locale = language
        },

      //loading控制
      loading(state, loading) {
        state.loading = loading
      },

    },
    actions: {

        //获取登录状态
        setUser({commit}, flag) {
            commit("userStatus", flag)
        },

        //获取登录状态
        setAvatar({commit}, avatar) {
            commit("saveAvatar", avatar)
        },

        //获取orgcodes
        setOrgcode({commit}, orgcode) {
            commit("orgCode", orgcode)
        },

      //获取real_orgcodes
      setRealOrgcode({commit}, orgcode) {
        commit("realOrgCode", orgcode)
      },

        //获取depcodes
        setDepcode({commit}, depcode) {
            commit("depCode", depcode)
        },

        //获取userCode,
        setUsercode({commit}, usercode) {
            commit("userCode", usercode)
        },

        //设置用户名称
        setUsername({commit}, username) {
            commit("userName", username)
        },
        //设置用户中文名
        setUserCname({commit}, usercname) {
            commit("userCname", usercname)
        },

        //获取Role
        setRole({commit}, roles) {
            commit("sRole", roles)
        },

        //获取权限列表
        setOrgcodeList({commit}, orgcodeList) {
            commit("sOrgcodeList", orgcodeList)
        },

        //获取rooms
        setRooms({commit}, rooms) {
            commit("rooms", rooms)
        },

        //获取acesstoekn,修改state里头的accessToken
        setAccessToken({commit}, accessToken) {
            commit("accessToken", accessToken)
        },
        //获取refershtoekn,修改state里头的refershToken
        setRefershToken({commit}, refershToken) {
            commit("refreshToken", refershToken)
        },

        //设置用户的菜单
        setTmpMenu({commit},payload) {
            return commit("updateTmpMenu",payload)
        },

        //设置当前系统
        setCurrentSys({commit},payload) {
            return commit("updateCurrentSys",payload)
        },

        //设置用户的菜单
        setUserMenus({commit}) {
            return commit("updateMenus")
        },

        //设置用户Tabs
        setUserTabs({commit}, payload) {
            commit("updateTabs", payload)
        },

        //设置用户的selectTab
        setSelectedTab({commit}, payload) {
            commit("updateSeleteTab", payload)
        },

        //打平菜单
        setFlatMenu({commit}) {
            commit("flatMenu")
        },

        //关闭单个tab
        setRemoveTab({commit}, payload) {
            commit("removeTab", payload)
        },

        //关闭多个tab
        setRemoveTabs({commit}, payload) {
            commit("removeTabs", payload)
        },

        //保存菜单更新
        setMenuSave({commit}, payload) {
            return commit("menuSave", payload)
        },

        //保存菜单更新(新增)
        setMenuAdd({commit}, payload) {
            return commit("menuAdd", payload)
        },

        //菜单删除
        setMenuDel({commit}, payload) {
            commit("menuDel", payload)
        },

        //退出登录
        logout({commit}){
            commit("clearall")
        },

        //设置语言
        setLanguage({commit}, language) {
            commit("localLanguage", language)
        },

      //设置loading
      setLoading({commit}, loading) {
        commit("loading", loading)
      },

    }
})
